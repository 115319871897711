import Splide from "@splidejs/splide";
import GLightbox from "glightbox";
import {FloatLabels} from "@keodesign/float-labels";

export function init() {
	const map_click_list = document.querySelectorAll(".map-click");
	if (map_click_list) {
		map_click_list.forEach((map_click) => {
			map_click.addEventListener("click", () => {
				// shield the map on mobile for easy scrolling
				map_click.style.display = "none";
			});
		});
	}
	setupSlider();
	GLightbox({
		touchNavigation: true,
		loop: true,
		autoplayVideos: false
	});
	new FloatLabels(".js-float-wrap");
}

export function setupSlider(){
	var elms = document.getElementsByClassName("slider");
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[i], {
			type   : "fade",
			rewind    : true,
			pagination: true,
			arrows    : false,
			autoplay: true,
		}).mount();
	}
}

window.addEventListener("DOMContentLoaded", init);